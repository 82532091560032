// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  ONBOARDING: '/onboarding',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    azure: {
      login: `${ROOTS.AUTH}/azure/login`,
      register: `${ROOTS.AUTH}/azure/register`,
    }
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    registrations: {
      root: `${ROOTS.DASHBOARD}/registrations`,
      // list: `${ROOTS.DASHBOARD}/registrations/list`,
    },
    admins: {
      root: `${ROOTS.DASHBOARD}/admins`,
      /*
      edit: (id: string) => `${ROOTS.DASHBOARD}/admins/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/admins/list`,
      new: `${ROOTS.DASHBOARD}/admins/new`,
      */
    },
    tenants: {
      root: `${ROOTS.DASHBOARD}/tenants`,
      /*
      edit: (id: string) => `${ROOTS.DASHBOARD}/admins/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/tenants/list`,
      new: `${ROOTS.DASHBOARD}/tenants/new`,
      */
    },
    logs: {
      root: `${ROOTS.DASHBOARD}/logs`,
      // list: `${ROOTS.DASHBOARD}/tenants/list`
    },


  },
};
