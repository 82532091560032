import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
const IndexPage = lazy(() => import('src/pages/dashboard/home'));

// TENANTS
const TenantsListPage = lazy(() => import('src/pages/dashboard/tenants/list'));
// const TenantsCreatePage = lazy(() => import('src/pages/dashboard/tenants/new'));
// const TenantsEditPage = lazy(() => import('src/pages/dashboard/tenants/edit'));

// REGISTRATIONS
const RegistrationsListPage = lazy(() => import('src/pages/dashboard/registrations/list'));
// const RegistrationsCreatePage = lazy(() => import('src/pages/dashboard/registrations/new'));
// const RegistrationsEditPage = lazy(() => import('src/pages/dashboard/registrations/edit'));

// ADMINS
const AdminsListPage = lazy(() => import('src/pages/dashboard/admins/list'));
// const AdminsCreatePage = lazy(() => import('src/pages/dashboard/admins/new'));
// const AdminsEditPage = lazy(() => import('src/pages/dashboard/admins/edit'));

// LOGS
const LogsListPage = lazy(() => import('src/pages/dashboard/logs/list'));


// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'registrations',
        children: [
          { element: <RegistrationsListPage />, index: true },
          { path: 'list', element: <RegistrationsListPage /> },
        ],
      },
      {
        path: 'tenants',
        children: [
          { element: <TenantsListPage />, index: true },
          { path: 'list', element: <TenantsListPage /> },
        ],
      },
      {
        path: 'admins',
        children: [
          { element: <AdminsListPage />, index: true },
          { path: 'list', element: <AdminsListPage /> },
        ],
      },
      {
        path: 'logs',
        children: [
          { element: <LogsListPage />, index: true },
          { path: 'list', element: <LogsListPage /> },
        ],
      },
    ],

  },
];
