/* eslint-disable no-nested-ternary */
import { useMemo, useCallback, useState, useEffect } from 'react';
// config
import { AZURE_API, MS_GRAPH_URL } from 'src/config-global';
//
import { MsalProvider, useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser/dist/utils/BrowserConstants';
import { AuthenticationResult } from '@azure/msal-browser';
import { AuthUserType } from 'src/auth/types';
import { UserAdminService } from 'src/services/usersettings-service';
import { msalGlobalInstance, setCurrentUserEmail } from './msal-global-instance';
import { AuthContext } from './auth-context';
// import { useBoolean } from 'src/hooks/use-boolean';

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

const getQueryParamValue = (queryKey: string): string | null => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    return params.get(queryKey);
};


const userEmail = getQueryParamValue('user');

const loginRequest = {
    loginHint: userEmail || '',
    scopes: AZURE_API.accessTokens,
    extraScopesToConsent: AZURE_API.accessTokens,
    // prompt: 'consent'
};


const getUserFromGraph = async (token: string) => {

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-type', 'application/json');

    // Add additional fields here
    const userDataFields = [
        'displayName',
        'department',
        'mail',
        'manager',
        'userPrinciplaName',
        'id',
        'tenantId',
    ];

    /*
    const groupDataFields = [
        'id',
        'displayName',
        'roleTemplateId'
    ];
    */

    const organizationDataFields = [
        'id',
        'displayName',
        'preferredLanguage'
    ];

    const requests = {
        requests: [
            {
                id: 1,
                method: "GET",
                url: `/me?$select=${userDataFields.join()}`
            },
            {
                id: 2,
                method: "GET",
                url: `/organization?$select=${organizationDataFields.join()}`
            }
        ]
    };

    const result = await Promise.all([
        fetch(`${MS_GRAPH_URL}/$batch`, { method: 'POST', headers, body: JSON.stringify(requests) })
            .then((response) => response.json()),
        fetch(`${MS_GRAPH_URL}/me/photo/$value`, { method: 'GET', headers })
            .then((response) => response.blob())]);

    const userData = result[0].responses.find((r: any) => r.id === '1').body;
    // const groupData = result[0].responses.find((r: any) => r.id === '2').body;
    const organizationData = result[0].responses.find((r: any) => r.id === '2').body;
    const photo = result[1];

    return [
        userData,
        photo,
        organizationData
    ]
};

const getCurrentUser = async (accessToken: string): Promise<AuthUserType> => {

    const userFromGraphTask = getUserFromGraph(accessToken);

    const isAdminTask = UserAdminService.isUserGlobalAdmin();

    const result = await Promise.all([userFromGraphTask, isAdminTask]);

    const [userData, photo, organizationData] = result[0];
    const isAdmin = result[1];
    // const isAdmin = false;

    const userRole = isAdmin ? "admin" : ""


    const user: AuthUserType = {
        displayName: userData.displayName,
        email: userData.mail,
        department: userData.department,
        manager: userData.manager,
        id: userData.id,
        tenantId: userData.tenatId,
        photoURL: URL.createObjectURL(photo),
        // role: groupData?.value?.[0]?.roleTemplateId === AZURE_API.adminRoleId ? "admin" : "",
        role: userRole,
        organization: {
            name: organizationData?.value?.[0]?.displayName,
            language: organizationData?.value?.[0]?.preferredLanguage,
            id: organizationData?.value?.[0]?.id
        }
    };

    // Initialize the current user email so that it can be used in axios requests
    setCurrentUserEmail(user.email);

    return user;
}

const AuthProviderWrapper = ({ children }: Props) => {
    const { instance: msalInstance, inProgress } = useMsal();
    const { result: loginResult, login: msalLogin, error: loginError } = useMsalAuthentication(InteractionType.Silent, loginRequest);
    const [user, setUser] = useState<AuthUserType | null>(null);
    const isAuthenticated = useIsAuthenticated();
    const [isBusy, setIsBusy] = useState<boolean>(inProgress !== InteractionStatus.None);


    // LOGIN
    const handleLoginWithRedirect = useCallback(async () => {
        await msalLogin(InteractionType.Redirect, loginRequest);
    }, [msalLogin]);

    /// LOGOUT
    const handleLogout = useCallback(async () => {
        const account = msalInstance.getActiveAccount();
        await msalInstance.logoutRedirect({ account });
        setUser(null);
    }, [msalInstance]);

    const initialize = useCallback(async () => {
        try {
            if (loginResult) {
                const loginResponse = loginResult as AuthenticationResult;
                msalInstance.setActiveAccount(loginResponse.account);
                const currentUser = await getCurrentUser(loginResponse.accessToken);
                setUser(currentUser);
                setIsBusy(false);
            }

            if (loginError) {
                setIsBusy(false);
            }
        } catch (error) {
            console.error(error);
            setIsBusy(false);
        }
    }, [loginResult, loginError, msalInstance]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    const checkAuthenticated = (loginResult || isAuthenticated) && user?.role === "admin" ? 'authenticated' : 'unauthenticated';
    const status = inProgress !== InteractionStatus.None || isBusy ? 'loading' : checkAuthenticated;

    const memoizedValue = useMemo(
        () => (
            {
                user: {
                    ...user
                },
                method: 'azure',
                loading: status === 'loading',
                authenticated: status === 'authenticated',
                unauthenticated: status === 'unauthenticated',
                login: handleLoginWithRedirect,
                logout: handleLogout
            })
        ,
        [handleLoginWithRedirect, handleLogout, status, user]
    );

    return (

        <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>

    );
}

export const AuthProvider = ({ children }: Props) => (
    <MsalProvider instance={msalGlobalInstance} >
        <AuthProviderWrapper>{children}</AuthProviderWrapper>
    </MsalProvider>
);











/*
const initialize = useCallback(async () => {
    try {
        
        let authResult = null;

        if (loading) {
            return
        }

        loading = true;
        setAuthStatus(AuthStatus.loading);

        if (isAuthenticated) {                                
            try {                    
                authResult = await msalInstance.acquireTokenSilent(loginRequest);
            }
            catch (e) {
                if (e instanceof InteractionRequiredAuthError) {
                    authResult = await msalInstance.acquireTokenRedirect(loginRequest);
                }
                else {
                    throw e;
                }                                                   
            }
        }
        else {
            authResult = await msalInstance.acquireTokenRedirect(loginRequest);
        }

        if (authResult) {
            const currentUser = await getCurrentUser(authResult.accessToken);
            setUser(currentUser);
            setAuthStatus(AuthStatus.authenticated);
        }
        else {
            setAuthStatus(AuthStatus.unauthenticated);
        }
            

    } catch (error) {
        console.error(error);
    }
    finally { 
        loading = false;
    }

}, [isAuthenticated, msalInstance]);
*/